@mixin flex-center {
  display: flex;
  align-items: center;
}

$primery-color: #0057e4;
@mixin transitionall {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.header-section {
  width: 100%;
  height: 62px;
  margin: 0;
  padding: 16px 70px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 9;
  & .toggle-btn {
    background: transparent;
    border: none;
    color: $primery-color;
    font-size: 23px;
    padding: 0;
  }
  .logo {
    margin-left: 120px;
  }
}

.right-section {
  @include flex-center;
  .rs-item-box {
    color: $primery-color;
    font-size: 20px;
    margin-left: 54px;
  }
}

.user-img-box {
  width: 30px;
  height: 30px;
  background: #c1d5f8;
  border-radius: 50%;
  @include flex-center;
  justify-content: center;
  span {
    font-size: 16px;
    color: #0057e4;
  }
}

.user-section {
  @include flex-center;
  .user-img-box {
    margin-right: 10px;
  }
  a.dropdown-item {
    text-decoration: none;
    padding: 8px 20px;
    color: #8a9fba;
  }
}

.user-dd {
  .btn {
    display: flex;
    background: none;
    border: none;
    padding: 0;
    &:focus {
      background: none;
      box-shadow: none;
      outline: none;
    }
    span {
      color: $primery-color;
    }
    .icon-ic-dropdown-blue {
      margin-top: 12%;
    }
  }
  .dropdown-menu {
    top: 15px !important;
  }
}

.user-dd.show {
  span {
    @include transitionall;
    &:before {
      transform: rotate(180deg) !important;
      display: inline-block;
    }
  }
}

.user-dd {
  .dropdown-toggle {
    &:after {
      display: none !important;
    }
  }
}

.user-section {
  .show {
    .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #fff !important;
      border-color: #fff !important;
      &:focus {
        box-shadow: none;
        &.active {
          background: none;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: #fff !important;
  border: none !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}

.home-link > a {
  text-decoration: none;
}

.dropdown-padding {
  padding: 8px 54px !important;
}

.handicon {
  cursor: pointer;
}
